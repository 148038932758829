<template>
  <Countdown
    mainColor="#ffffff"
    labelColor="#ffffff"
    secondFlipColor="#ffffff"
    mainFlipBackgroundColor="#992932"
    secondFlipBackgroundColor="#992932"
    :deadline="setNextDate"
    labelSize=".8rem"
    countdownSize="3.3rem"
    :labels="{
      days: 'дней',
      hours: 'часов',
      minutes: 'минут',
      seconds: 'секунд',
    }"
    class="newCounter"
  />
</template>

<script>
import { Countdown } from "vue3-flip-countdown";
export default {
  components: { Countdown },
  data() {
    return {};
  },
  methods: {
    getNextMonday() {
      let date = new Date();
      date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7));
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    },
  },
  computed: {
    setNextDate() {
      return "2025-04-30";
    },
  },
};
</script>

<style lang="scss">
// .flip-clock.newCounter {
//   margin: 0;
// }
// .flip-clock__piece.flip {
//   background-color: transparent;
// }

// .flip-clock__card .flip-card > br {
//   font-weight: 300;
// }
// .flip-card__top,
// .flip-card__back,
// .flip-card__bottom,
// .flip-card__back-bottom {
//   font-weight: 300;
//}
</style>
