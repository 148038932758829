<template>
  <section id="main-slider-1" class="main-swiper-container">
    <swiper
      class="swiper-main"
      :modules="modules"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
    >
      <swiper-slide class="swiper-main-slide swiper-main-slide--counter">
        <div class="first-swiper__image first-swiper__image-1"></div>
        <div class="swiper-main-slide__content">
          <div class="swiper-main-slide__counter">
            <TimeCounter />
          </div>
          <button @click="toggleRequestForm($event)" class="swiper-main-slide__content__button btn-primary">
            Зафиксируйте лучшие условия
          </button>
        </div>
      </swiper-slide>

      <!-- <swiper-slide class="swiper-main-slide">
        <div class="first-swiper__image first-swiper__image-2"></div>
      </swiper-slide> -->

    </swiper>
  </section>
</template>
<!--  -->

<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import TimeCounter from "./TimeCounter.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    TimeCounter,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title:
          "Оставьте заявку на звонок менеджера и зафиксируйте лучшие условия на покупку Chery",
        form_position: "формы  Зафиксируйте лучшие условия ",
      });
    },
  },
};
</script>
